import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: 'Arial';
  color: #393737;
  background-color: #F8F8F8 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  opacity: 0.75;
  background: #3d424a;
  z-index: -1
}

/* Position */

.col-10{
  margin: 0 auto;
  width: 90vw;
  max-width: 2000px;
}

.col-vw-7{
  margin: 0 auto;
  max-width: 70vw;

  @media only screen and (max-width: 798px) {
    max-width: 90vw;
  }
}

/* Typographic  */

  h1 {
    font-size: 32px;
  }

  h2{
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  p{
    font-size: 18px;
  }

  @media screen and (max-width: 798px){
    h1 {
    font-size: 28px;
    }

    h2{
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    p{
      font-size: 15px;
    }
  }

  .font-title {
    text-transform: uppercase;
    font-weight: 900;
  }
  
  .font-subtitle{
    font-weight: 600;
  }

  .font-paragraph {
    line-height: 35px;
    text-align: justify;
  }


  .font-white {
    color: #F0EBEB;
  }

  .font-dark{
  color: #393737;
  }
  .font-center{
    text-align: center;
  }

 .centerDiv {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, 0%);
 }


 .linkWithoutEffect{
   color: inherit;
   text-decoration: none;
 }

 /* Margins */
 .mt-1{
  margin-top: 10px;
 }
 .mt-2{
  margin-top: 20px;
 }

 .mt-3px{
  margin-top: 30px;
 }

 .mt-4{
  margin-top: 40px;
 }

 .mb-1 {
   margin-bottom: 10px;
 }
 .mb-2 {
   margin-bottom:20px;
 }

 .mb-4 {
   margin-bottom: 40px;
 }

 .mtb-1{
  margin: 10px 0 10px;
 }

 .mtb-2{
  margin: 20px 0 20px;
 }

 .ml-1{
   margin-left: 10px;
 }

 .mr-1{
   margin-right: 10px;
 }

 /* Buttons */

 .button{
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: .125rem;

  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
 
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

  padding: .84rem 2.14rem;

  font-size: .81rem;
  text-decoration: none;
  margin: 0;

  :hover {
    opacity: 0.8;
    box-shadow: 0 4px 7px 0 rgb(0 0 0 / 16%), 0 4px 12px 0 rgb(0 0 0 / 12%);
  }
 }

 .primary-button {
  background: #dd4b39;
  color: #fffcfc;
  font-weight: bold;
 }

 .white-button {
  background: #fffcfc;
  color: #393737;
  font-weight: bold;
 }

.btn {
  position: relative;
    padding: 5px 20px;
    width: auto;
    border-radius: 10px;
    cursor: pointer;

    :focus, :active {
      outline:0;
    }
  }

  .btn-primary{
    background: #f11c1c;
    color: #fffcfc;

    border: 1px solid #f11c1c;
    font-weight: 800;
  }
  
  .btn-invert {
    background: #fffcfc;
    color: #f11c1c;

    border: 1px solid #fffcfc;

    font-weight: 800;
  }

  .btn-black {
    background: #fffcfc;
    color: #393737;;
    border: 1px solid #fffcfc;
    font-weight: 600;
  }  

  .btn-socialMedia{
    width: 30px;
    height: 30px;
    border-radius: 2px;

    background-color: #393737;
    border: 1px solid #696969;
    color: #fffcfc;  

    :hover{
      cursor: pointer;
      background-color: #696969;
    }

  }

  .btnAnimatedLink {
    transition: all 0.5s;
    

      :after {
        content: '»';
        font-size: 20px;
        position: absolute;
        opacity: 0;  
        top: 0%;
        right: -20%;
        transition: 0.5s;
      }

      :hover:after {
      opacity: 1;
      right: 5%;
    }
  }

  .btnFooterContact {

    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;

    &:hover {
    background: #e8e5e5;
    letter-spacing: 0.5px;
    -webkit-box-shadow: 0px 5px 40px -10px #f34949;
    -moz-box-shadow: 0px 5px 40px -10px #f34949;
    box-shadow: 5px 40px -10px #f34949;
    transition: all 0.4s ease 0s;
  }

  }

  .displayNone{
    display: none;
  }

  /* CARROUSEL */
  .slideHorizontal___1NzNV{
    display: flex;
    justify-content: center;
  }

  /* GALLERY IMAGE */

  #SRLLightbox{
  z-index: 9999;
  }

  .carousel-dotGroup {
        display: flex;
        justify-content: center;

        button {
            margin: 0 5px;
            width: 10%;
            height: 3px;

            border: none;
            outline: none;
            background-color: rgb(204 200 200);
            transition: background-color 300ms ease;
        }

        .carousel__dot--selected {
            background-color: #de2e2e;
        }
    }

`

export default GlobalStyle
