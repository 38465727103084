import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import GlobalStyle from '../assets/styles/globalStyle'
import 'minireset.css'

import Header from './header'
import Footer from './footer'

import WhatsappWidget from '../components/widgets/Whatsapp'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <GlobalStyle />
            <Header siteTitle={data.site.siteMetadata.title} />

            <main>{children}</main>

            {/* <ScrollTop /> */}
            <WhatsappWidget />
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
