import styled from 'styled-components'

export const Menu = styled.header`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;

    .contactInfo {
        height: 40px;
        background-color: #f1f1f1;
        .socialMedia {
            max-width: 2000px;
            padding: 5px 5vw;
            margin: 0 auto;

            display: flex;
            justify-content: space-between;

            ul li {
                display: inline;
                .socialIcon {
                    width: 25px;
                }
            }

            .listContact {
                display: flex;

                p {
                    position: relative;
                    display: inline;
                    bottom: 6px;
                    font-size: 14px;
                }
                .socialIcon {
                    margin: 0 15px;
                }
            }

            .listMedia {
                li {
                    margin-left: 15px;
                    transition: transform 0.2s;
                    :hover {
                        cursor: pointer;
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }

    .header {
        background-color: #f8f8f8;
        height: 80px;

        #nav-check,
        .nav-mobile {
            display: none;
        }

        .headerButtons {
            max-width: 2000px;
            margin: 0 auto;
            padding: 0.5vw 5vw;
            display: flex;
            justify-content: space-between;

            .logoMinas {
                width: 125px;
            }

            .menuMobile {
                font-size: 32px;
                color: #393737;
            }

            #check,
            .checkbtn {
                cursor: pointer;
                display: none;
            }

            .onlyMobile {
                display: none;
            }

            .responsiveNavbar {
                display: flex;
                align-items: center;

                .linkPages {
                    display: flex;
                    font-size: 13px;

                    .itemLink {
                        text-align: center;
                        margin-left: 2vw;
                        align-self: center;

                        .iconSearchHeader {
                            font-size: 20px;
                        }

                        .linkHeader {
                            color: #393737;
                            text-transform: uppercase;
                            text-decoration: none;
                            font-weight: 900;
                        }

                        .activeLinkHeader {
                            color: #d22327;
                            text-decoration: none;
                            :hover {
                                cursor: default;
                            }
                        }

                        :hover {
                            .linkHeader {
                                :not(.activeLinkHeader) {
                                    cursor: pointer;
                                    color: #a9a8a8;
                                }
                            }
                        }
                    }
                }
            }
        }

        .dropdown {
            position: relative;
            display: inline-block;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            padding: 12px 16px;
            border-radius: 6px;
            z-index: 1;
        }

        .dropdown:hover .dropdown-content {
            display: block;
        }

        .dropdown-link-item {
            width: 100%;
            margin-top: 8px;

            text-align: start;
        }
    }

    @media only screen and (max-width: 798px) {
        .checkbtn {
            display: flex !important;
            align-items: center;

            .closeBtnMenu {
                display: none;
            }

            .openBtnMenu {
                display: block;
            }
        }

        .responsiveNavbar {
            display: block !important;
            position: fixed;
            width: 100%;
            height: 100%;
            background: #f8f8f8;
            top: 80px;
            right: -100%;
            transition: all 1s;
            z-index: 1000;
            padding: 15px 12px 5px 12px;
            font-size: 1em;

            .onlyMobile {
                display: block !important;
                .listContactMobile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    margin-top: 32px;
                    font-size: 0.9em;

                    font-weight: 700;
                    a {
                        flex-grow: 1;

                        flex-basis: 50%;
                        max-width: fit-content;
                        margin: 0 16px;
                    }
                }
            }

            .linkPages {
                .itemLink {
                    margin-top: 16px;
                }
                position: relative;
                flex-direction: column;
                align-items: center;
                font-size: 14px !important;
            }

            .listMedia {
                display: flex;
                justify-content: space-evenly;
                margin-top: 16px;
            }
        }

        #check:checked {
            ~ .responsiveNavbar {
                right: 0;
            }
            ~ .checkbtn {
                .openBtnMenu {
                    display: none !important;
                }
                .closeBtnMenu {
                    display: block;
                }
            }
        }
        .contactInfo {
            display: none;
        }
    }
`

export const QuotationsContainer = styled.section`
    background-color: #f8f8f8;
    width: 100%;
    .quotations-content {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;

        padding: 5px 0;

        display: flex;
        justify-content: space-evenly;
    }

    .individual-flag-quotations {
        display: flex;
        align-content: center;
        align-items: center;
        .coinValue {
            font-size: 16px;
        }
        .icon-flag-quotations {
            width: 24px;
            margin-right: 10px;
        }
    }

    .reference-quotations-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5px 0;

        a {
            color: #393737;
            :hover {
                color: #666363;
            }
        }

        .divider-reference-quotations {
            margin: 0 5px;
        }

        .coinDate {
            margin: 2px 0;
            font-size: 14px;
        }
    }
`

export const SolutionsOptions = styled.div`
    margin-top: 12px;

    ul li {
        margin: 8px 0;
        text-align: left;
        font-weight: 100 !important;
        color: red !important;
    }
`
