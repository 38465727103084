import React from 'react'
import DividerSpan from './styles'

const Divider = (props) => {
    const { size, orientation } = props

    const dividerSize = `${8 * size}px`

    return <DividerSpan size={dividerSize} orientation={orientation} />
}

export default Divider
