import styled from 'styled-components'

import IndustryHelpImage from '../../assets/images/background/help-industry.jpg'

export const Container = styled.footer`
    left: 0;
    bottom: 0;
    width: 100%;

    .help-wrapper {
        background-image: url(${IndustryHelpImage});
        background-size: cover;
        background-repeat: no-repeat;
        color: #ffffff;
        z-index: 0;
        position: relative;
        padding: 2.2em 0;
        background-position: bottom center !important;
        background-attachment: fixed;
        overflow: hidden;

        ::after {
            position: absolute;
            top: 0;
            left: -50px;
            bottom: 0;
            content: ' ';
            opacity: 0.95;
            background: #dd4b39;
            z-index: -1;
            width: 55%;
            transform: skewX(20deg);
            height: 105%;
        }

        .help-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            margin: 0 auto;
            width: 60vw;
        }

        .help-text-item {
            flex-grow: 1;
            max-width: 60%;
            flex-basis: 60%;
        }

        .help-button-item {
            flex-grow: 1;
            max-width: 40%;
            flex-basis: 40%;
        }
    }

    @media only screen and (max-width: 750px) {
        .help-content {
            width: 80vw !important;
        }

        .help-text-item {
            max-width: 100% !important;
            flex-basis: 100% !important;

            margin-bottom: 32px;
        }

        .help-button-item {
            max-width: 100% !important;
            flex-basis: 100% !important;
        }
    }

    .wrapperFooter {
        background-color: #f8f8f8;
        padding: 2vw 5vw 1vw;

        .footerInfo {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .footerAbout {
                flex-grow: 1;
                max-width: 60%;
                flex-basis: 60%;
            }

            .footerQuickLinks {
                flex-grow: 1;
                max-width: 40%;
                flex-basis: 40%;

                text-align: right;
                ul li {
                    margin: 8px 0;
                    transition: 0.3s all ease;
                    :hover {
                        cursor: pointer;
                        transform: scale(1.01);
                    }
                }
            }
        }

        .footer-about-content {
            display: flex;
            flex-wrap: wrap;
        }

        .footer-about-item {
            flex-grow: 1;
            max-width: auto;
            flex-basis: auto;
        }

        @media only screen and (max-width: 750px) {
            .footerInfo {
                text-align: center;
                .footerAbout {
                    max-width: 100%;
                    flex-basis: 100%;

                    margin: 16px 0;
                }

                .footerQuickLinks {
                    max-width: 100%;
                    flex-basis: 100%;

                    text-align: center;
                }
            }

            .footer-about-item {
                flex-grow: 1;
                max-width: 100%;
                flex-basis: 100%;
            }
        }

        .containerQuotations {
            display: flex;
            flex-direction: column;
            align-items: center;

            .titleQuotation {
                border-left: 3px solid #d22327;
                padding: 0px 5px;
                letter-spacing: 2px;
                margin-bottom: 10px;
                ::first-letter {
                    font-size: 125%;
                    color: #cb3f41;
                }
            }

            .wrapperQuotations {
                display: flex;
            }
        }

        .footerCredits {
            text-align: center;

            .logoPersonalFooter {
                :hover {
                    transform: scale(1.1);
                }
            }
        }
    }
`
