import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Menu } from './styles'

import { FiMenu } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'

const Header = () => {
    const {
        logoMinas,
        instagramIcon,
        linkedinIcon,
        whatsappIcon,
        youtubeIcon,
        emailIcon,
        telephoneIcon,
    } = useStaticQuery(graphql`
        query {
            logoMinas: allFile(filter: { relativePath: { eq: "logoMinasCoding.png" } }) {
                nodes {
                    childImageSharp {
                        fixed(width: 125, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            instagramIcon: allFile(
                filter: { relativePath: { eq: "icons/socialMedia/instagramIcon.png" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 28, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            linkedinIcon: allFile(
                filter: { relativePath: { eq: "icons/socialMedia/linkedinIcon.png" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 28, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            whatsappIcon: allFile(
                filter: { relativePath: { eq: "icons/socialMedia/whatsappIcon.png" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 28, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            youtubeIcon: allFile(
                filter: { relativePath: { eq: "icons/socialMedia/youtubeIcon.png" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 28, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            emailIcon: allFile(
                filter: { relativePath: { eq: "icons/socialMedia/emailIcon.png" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 28, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            telephoneIcon: allFile(
                filter: { relativePath: { eq: "icons/socialMedia/telephoneIcon.png" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(width: 28, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `)

    const [viewModalButton, setViewModalButton] = useState(false)

    const handleViewMobileButtonChange = () => {
        setViewModalButton((currentState) => !currentState)
    }

    return (
        <Menu>
            <section className="contactInfo">
                <div className="socialMedia">
                    <div className="listContact">
                        <div>
                            <Img
                                fixed={emailIcon.nodes[0].childImageSharp.fixed}
                                alt="Email - Minas Coding"
                                className="socialIcon"
                            />
                            <p>mkt@minascoding.com.br</p>
                        </div>
                        <div>
                            <Img
                                fixed={telephoneIcon.nodes[0].childImageSharp.fixed}
                                alt="Telefone - Minas Coding"
                                className="socialIcon"
                            />
                            <p>(31) 2510-3300</p>
                        </div>
                    </div>

                    <ul className="listMedia">
                        <li>
                            <a
                                href="https://api.whatsapp.com/send?phone=5531971787982&text=Ol%C3%A1!%20Gostaria%20de%20ser%20atendido!"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Img
                                    fixed={whatsappIcon.nodes[0].childImageSharp.fixed}
                                    alt="Whatsapp - Minas Coding"
                                    className="socialIcon"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/minas-coding/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Img
                                    fixed={linkedinIcon.nodes[0].childImageSharp.fixed}
                                    alt="Linkedin - Minas Coding"
                                    className="socialIcon"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.youtube.com/channel/UCed0b5Lwn4EiF6kCBiwy1hQ/videos"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Img
                                    fixed={youtubeIcon.nodes[0].childImageSharp.fixed}
                                    alt="Yotube - Minas Coding"
                                    className="socialIcon"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/minascoding/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Img
                                    fixed={instagramIcon.nodes[0].childImageSharp.fixed}
                                    alt="Instagram - Minas Coding"
                                    className="socialIcon"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="header">
                <div className="headerButtons">
                    <Link to="/">
                        <Img
                            fixed={logoMinas.nodes[0].childImageSharp.fixed}
                            className="logoMinas"
                            alt="Logomarca - Minas Coding"
                        />
                    </Link>

                    <input
                        type="checkbox"
                        id="check"
                        value={viewModalButton}
                        onChange={handleViewMobileButtonChange}
                    />
                    <label htmlFor="check" className="checkbtn">
                        <FiMenu className="menuMobile openBtnMenu" />
                        <MdClose className="menuMobile closeBtnMenu" />
                    </label>

                    <div className="responsiveNavbar">
                        <div className="linkPages">
                            <div className="itemLink">
                                <Link
                                    to="/"
                                    className="linkHeader"
                                    activeClassName="activeLinkHeader"
                                >
                                    Home
                                </Link>
                            </div>
                            <div className="itemLink">
                                <Link
                                    to="/sobre/quem-somos"
                                    className="linkHeader"
                                    activeClassName="activeLinkHeader"
                                >
                                    Empresa
                                </Link>
                            </div>

                            <div className="itemLink">
                                <div class="dropdown linkHeader">
                                    <span>Soluções</span>

                                    <div className="dropdown-content">
                                        <div className="dropdown-link-item">
                                            <Link
                                                to="/solucoes/tecnologia"
                                                activeClassName="activeLinkHeader"
                                                className="linkHeader"
                                            >
                                                Tecnologia
                                            </Link>
                                        </div>

                                        <div className="dropdown-link-item">
                                            <Link
                                                to="/solucoes/setor"
                                                activeClassName="activeLinkHeader"
                                                className="linkHeader"
                                            >
                                                Por setor
                                            </Link>
                                        </div>

                                        <div className="dropdown-link-item">
                                            <Link
                                                to="/solucoes/varejo"
                                                activeClassName="activeLinkHeader"
                                                className="linkHeader"
                                            >
                                                Varejo
                                            </Link>
                                        </div>

                                        <div className="dropdown-link-item">
                                            <Link
                                                to="/solucoes/softwares"
                                                activeClassName="activeLinkHeader"
                                                className="linkHeader"
                                            >
                                                Softwares
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                {/* <SolutionsOptions>
                                        <ul className="solutions-options">
                                            <li>
                                                <Link
                                                    className="linkHeader"
                                                    activeClassName="activeLinkHeader"
                                                    to="/solucoes/tecnologias"
                                                >
                                                    Por tecnologia
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="linkHeader"
                                                    activeClassName="activeLinkHeader"
                                                    to="/solucoes/setores"
                                                >
                                                    Por setor
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="linkHeader"
                                                    activeClassName="activeLinkHeader"
                                                    to="/solucoes/varejo"
                                                >
                                                    Varejo
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="linkHeader"
                                                    activeClassName="activeLinkHeader"
                                                    to="/solucoes/softwares"
                                                >
                                                    Softwares
                                                </Link>
                                            </li>
                                        </ul>
                                    </SolutionsOptions> */}
                            </div>

                            <div className="itemLink">
                                <Link
                                    className="linkHeader"
                                    activeClassName="activeLinkHeader"
                                    to="/servicos"
                                >
                                    Serviços
                                </Link>
                            </div>
                            <div className="itemLink">
                                <Link
                                    className="linkHeader"
                                    activeClassName="activeLinkHeader"
                                    to="/regioes"
                                >
                                    Regiões
                                </Link>
                            </div>
                            <div className="itemLink">
                                <Link
                                    className="linkHeader"
                                    activeClassName="activeLinkHeader"
                                    to="/meio-ambiente"
                                >
                                    Meio Ambiente
                                </Link>
                            </div>
                            <div className="itemLink">
                                <Link
                                    className="linkHeader"
                                    activeClassName="activeLinkHeader"
                                    to="/fale-conosco"
                                >
                                    Fale conosco
                                </Link>
                            </div>
                        </div>

                        <div className="onlyMobile">
                            <div className="listContactMobile">
                                <a
                                    className="font-white linkWithoutEffect"
                                    href="tel:(31) 2510-3300"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Img
                                        fixed={emailIcon.nodes[0].childImageSharp.fixed}
                                        alt="Email - Minas Coding"
                                        className="socialIcon"
                                    />
                                </a>
                                <a
                                    className="font-white linkWithoutEffect"
                                    href="tel:(31) 2510-3300"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Img
                                        fixed={telephoneIcon.nodes[0].childImageSharp.fixed}
                                        alt="Telefone - Minas Coding"
                                        className="socialIcon"
                                    />
                                </a>
                            </div>

                            <ul className="listMedia">
                                <li>
                                    <a
                                        href="https://api.whatsapp.com/send?phone=5531971787982&text=Ol%C3%A1!%20Gostaria%20de%20ser%20atendido!"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Img
                                            fixed={whatsappIcon.nodes[0].childImageSharp.fixed}
                                            alt="Whatsapp - Minas Coding"
                                            className="socialIcon"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.linkedin.com/in/davicecilio/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Img
                                            fixed={linkedinIcon.nodes[0].childImageSharp.fixed}
                                            alt="Linkedin - Minas Coding"
                                            className="socialIcon"
                                        />
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="https://www.youtube.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Img
                                            fixed={youtubeIcon.nodes[0].childImageSharp.fixed}
                                            alt="Yotube - Minas Coding"
                                            className="socialIcon"
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.youtube.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Img
                                            fixed={instagramIcon.nodes[0].childImageSharp.fixed}
                                            alt="Instagram - Minas Coding"
                                            className="socialIcon"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Menu>
    )
}

export default Header
