import styled from 'styled-components'

export default styled.a`
    position: fixed;
    bottom: 1rem;
    right: 1rem;

    width: 60px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.3s all ease;

    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    font-size: 36px;
    z-index: 100;
    cursor: pointer;

    :hover {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        transform: translateY(-5px);
        -webkit-box-shadow: 0 5px 15px 2px rgba(37, 211, 102, 0.3);
        -moz-box-shadow: 0 5px 15px 2px rgba(37, 211, 102, 0.3);
        box-shadow: 0 5px 15px 2px rgba(37, 211, 102, 0.3);
    }
`
