import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'

import WidgetContainer from './styles'

const WhatsappWidget = () => {
    return (
        <WidgetContainer
            href="https://api.whatsapp.com/send?phone=5531971787982&text=Ol%C3%A1!%20Gostaria%20de%20ser%20atendido!"
            target="_blank"
            rel="noopener noreferrer"
        >
            <FaWhatsapp />
        </WidgetContainer>
    )
}

export default WhatsappWidget
