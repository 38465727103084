import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Container } from './styles'
import Divider from '../../components/Divider'

const Footer = () => {
    const { logoMinas, logoCecilio } = useStaticQuery(graphql`
        query {
            logoMinas: allFile(filter: { relativePath: { eq: "logoMinasCoding.png" } }) {
                nodes {
                    childImageSharp {
                        fixed(width: 125, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }

            logoCecilio: allFile(filter: { relativePath: { eq: "logos/logoCecilio.png" } }) {
                nodes {
                    childImageSharp {
                        fixed(width: 40, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `)

    return (
        <Container>
            <div className="help-wrapper">
                <span className="overlay" />

                <div className="help-content">
                    <div className="help-text-item">
                        <h2 className="font-white font-subtitle">Ficou com alguma dúvida?</h2>

                        <p className="font-white">Teremos todo prazer em ouvi-lo!</p>
                    </div>

                    <div className="help-button-item">
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeR_8lUdJaeBJGco0NRmvsbwo47jRZNzNoh_3UlS6txN9adJA/viewform?usp=sf_link"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="button primary-button"
                        >
                            Entre em contato
                        </a>
                    </div>
                </div>
            </div>

            <div className="wrapperFooter">
                <div className="footerInfo">
                    <div className="footerAbout">
                        <div className="footer-about-content">
                            <div className="footer-about-item">
                                <Img
                                    fixed={logoMinas.nodes[0].childImageSharp.fixed}
                                    alt="Logo - Minas Coding"
                                />
                            </div>

                            <Divider size={5} orientation="vertical" />

                            <div className="footer-about-item">
                                <p>
                                    Empresa de Automação Industrial com soluções em Codificação
                                    Industrial, Embalagem, Inspeção e Controle de Qualidade,
                                    Robótica Industrial e Linha de Varejo e Software para integração
                                    de Sistemas ERP.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footerCredits mt-4">
                    <p>
                        <strong>
                            Minas Coding © {`${new Date().getFullYear()} - `}
                            Todos os direitos reservados
                        </strong>
                    </p>
                    <hr />
                    <a
                        href="https://www.linkedin.com/in/davicecilio/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            fixed={logoCecilio.nodes[0].childImageSharp.fixed}
                            alt="Logo - Davi Cecílio WebDeveloper"
                            className="logoPersonalFooter mt-2"
                        />
                    </a>
                </div>
            </div>
        </Container>
    )
}

export default Footer
